import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DotLoader } from "../Component/Custom Loader/CustomLoader";
import { BACKEND_BASE_URL } from "../Component/GlobalVariables";

const UserProfileDashboard = () => {
  const [userInfo, setUserInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const fetchData = () => {
  //   setIsLoading(true);
  //   axios
  //     .get(`${BACKEND_BASE_URL}/api/v2/user/profile`, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${localStorage.getItem("USER_ACCESS_TOKEN")}`,
  //       },
  //     })

  //     .then((res) => {
  //       setIsLoading(false);
  //       setUserInfo(res.data?.user_profile);
  //     });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <div className="user_dashboard_content p-4 p-lg-5">
      <h1 className="mb-5 ms-3 ms-lg-0">Account Overview</h1>
      <h3 className="mb-4">Profile</h3>

      <div>
        {!isLoading && (
          <>
            <Form.Group className="d-flex align-items-center form_group">
              <Form.Label className="w-50 mb-0">Username</Form.Label>
              <Form.Control
                className="w-50"
                type="text"
                placeholder="Username"
                defaultValue={userInfo?.name}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center form_group">
              <Form.Label className="w-50 mb-0">Email</Form.Label>
              <Form.Control
                className="w-50"
                type="text"
                placeholder="Email"
                defaultValue={userInfo?.email}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center form_group">
              <Form.Label className="w-50 mb-0">Profile Photo</Form.Label>
              <div>
                {localStorage.getItem("LOGGED_IN_USER_PROFILE_PIC") != null ? (
                  <img
                    src={`${BACKEND_BASE_URL}${userInfo?.profile_photo}`}
                    alt="Profile"
                    className="img-fluid"
                    width={80}
                  />
                ) : (
                  <img
                    src={require("../Assets/avatar.png")}
                    alt=""
                    className="img-fluid"
                    width={80}
                  />
                )}
              </div>
            </Form.Group>
            <Form.Group className="d-flex align-items-center form_group">
              <Form.Label className="w-50 mb-0">Phone Number</Form.Label>
              <Form.Control
                className="w-50"
                type="text"
                placeholder="phone"
                defaultValue={userInfo?.phone}
              />
            </Form.Group>
          </>
        )}
      </div>

      {isLoading && (
        <div className="flex_center" style={{ minHeight: "365px" }}>
          <DotLoader />
        </div>
      )}

      <div className="edit_profile_btn mt-4 mb-5">
        <button>
          <Link to="/user/profile/edit-profile">Edit Profile</Link>
        </button>
      </div>

      <h3 className="mb-4">Sign out everywhere</h3>
      <div className="note mb-4">
        Note: It can take up to 1 hour for sign out to take effect on the web
        player.
      </div>
      <div className="signout_everywhere_btn">
        <button>Sign out everywhere</button>
      </div>
    </div>
  );
};

export default UserProfileDashboard;
