import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Table } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { UserContext } from "../App";
import { BACKEND_BASE_URL } from "../Component/GlobalVariables";

const UserReceipts = () => {
  const [userInfo, setUserInfo] = useState([]);

  const fetchData = () => {
    axios
      .get(`${BACKEND_BASE_URL}/api/v2/user/profile`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("USER_ACCESS_TOKEN")}`,
        },
      })

      .then((res) => {
        setUserInfo(res.data?.user_profile);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="user_dashboard_content p-4 p-lg-5">
      <h1 className="mb-5 ms-3 ms-lg-0">Receipts</h1>
      <div className="receipt">
        <Table responsive style={{ minWidth: "max-content" }}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Order Number</th>
              <th>Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>5/12/22</td>
              <td>sfsdfsdf1216547sf</td>
              <td>300.00</td>
              <td>
                <Link to="/user/profile/receipts/1">View details</Link>
              </td>
            </tr>
            <tr>
              <td>5/12/22</td>
              <td>sfsdfsdf1216547sf</td>
              <td>300.00</td>
              <td>
                <Link to="/user/profile/receipts/1">View details</Link>
              </td>
            </tr>
            <tr>
              <td>5/12/22</td>
              <td>sfsdfsdf1216547sf</td>
              <td>300.00</td>
              <td>
                <Link to="/user/profile/receipts/1">View details</Link>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default UserReceipts;
