import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { BACKEND_BASE_URL } from "../Component/GlobalVariables";
import { PassInputFieldFormControl } from "../Component/PassInputField";
import { ToastSuccess } from "../Component/ToastAlert";

const UserProfileEdit = () => {
  const userName = useRef();
  const userProfilePic = useRef();
  const userAddress = useRef();
  const userPhone = useRef();

  // Fetch all User Info
  const [userInfo, setUserInfo] = useState();

  const [error, setError] = useState([]);

  // const fetchData = () => {
  //   axios
  //     .get(`${BACKEND_BASE_URL}/api/v2/user/profile`, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${localStorage.getItem("USER_ACCESS_TOKEN")}`,
  //       },
  //     })

  //     .then((res) => {
  //       setUserInfo(res.data?.user_profile);
  //       localStorage.setItem(
  //         "LOGGED_IN_USER_PROFILE_PIC",
  //         res.data?.user_profile?.profile_photo
  //       );
  //     });
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  // Image Preview
  const [files, setFile] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  return (
    <div className="user_dashboard_content p-4 p-lg-5">
      <h1 className="mb-5 ms-3 ms-lg-0">Edit Profile</h1>

      <div className="edit_profile">
        <Form autoComplete="off">
          <Form.Group className="user_dashboard_content mb-4">
            <Form.Label className="">Username</Form.Label>
            <Form.Control
              className=""
              autoComplete="off"
              defaultValue={userInfo?.name}
              ref={userName}
            />
          </Form.Group>

          <Form.Group className="user_dashboard_content mb-4">
            <Form.Label className="mb-2">Email</Form.Label>
            <Form.Control
              className=""
              type="email"
              placeholder="Email"
              // disabled
              defaultValue={userInfo?.email}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label className="mb-2">Profile Photo</Form.Label>
            <Form.Control
              type="file"
              ref={userProfilePic}
              onChange={handleImgPreview}
            />
            {files.map((file, key) => {
              return (
                <div key={key} className="Row">
                  <span className="Filename">
                    <img
                      width={80}
                      height={50}
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                    />
                  </span>
                </div>
              );
            })}
            {files.length == 0 && userInfo?.profile_photo && (
              <img
                width={80}
                src={`${BACKEND_BASE_URL}/${userInfo?.profile_photo}`}
                alt={userInfo.name}
                className="img-thumbnail me-2 my-2"
              />
            )}
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label className="mb-2">
              Address: <span className="text-danger">*</span>
            </Form.Label>

            <Form.Control
              as="textarea"
              type="textarea"
              rows={3}
              placeholder="Enter your address"
              className=""
              defaultValue={userInfo?.address}
              ref={userAddress}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label className="mb-2">
              Phone Number: <span className="text-danger">*</span>
            </Form.Label>

            <Form.Control
              placeholder="Phone"
              aria-label="Phone"
              className=""
              defaultValue={userInfo?.phone}
              ref={userPhone}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label className=" mb-2">Country or region</Form.Label>
            <Form.Control
              className=""
              type="text"
              placeholder="Bangladesh"
              defaultValue="Bangladesh"
            />
          </Form.Group>

          <div className="save_profile_btn mt-4 mb-5 flex_end">
            <button className="me-3">Cancel</button>
            <button onClick={() => {}}>Save Profile</button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UserProfileEdit;
