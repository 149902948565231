import React, { useContext, useState } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";

import {
  AiFillCaretDown,
  AiFillHome,
  AiOutlineHome,
  AiOutlineMenu,
  AiOutlineProject,
} from "react-icons/ai";
import { BsInbox, BsPencilFill, BsThreeDotsVertical } from "react-icons/bs";
import {
  MdNotifications,
  MdNotificationsNone,
  MdPassword,
} from "react-icons/md";
import { BiLogOut, BiPencil, BiTimeFive } from "react-icons/bi";
import { HiLockClosed, HiOutlineTemplate } from "react-icons/hi";
import "./userProfileDashboard.css";
import { Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { BACKEND_BASE_URL } from "../Component/GlobalVariables";

const UserProfile = () => {
  const [toggleNav, setToggleNav] = useState(false);

  return (
    <div style={{ backgroundColor: "#1c273b" }}>
      <div className="user_profile_navbar ">
        <Navbar expand="lg" className="custom_container">
          <Navbar.Brand href="#">
            <img
              src={require("../Assets/logo-white.png")}
              alt=""
              className="img-fluid"
              width={120}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-white">
              <Nav.Link href="#home" className="me-3">
                Support
              </Nav.Link>
              <Nav.Link href="#link" className="me-3">
                <span>Download</span>
              </Nav.Link>
              <div className="text logo_img_wrapper me-3">
                <img
                  src={`${BACKEND_BASE_URL}${localStorage.getItem(
                    "LOGGED_IN_USER_PROFILE_PIC"
                  )}`}
                  alt="Profile"
                  className="img-fluid"
                />
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="bg-transparent border-0"
                >
                  Profile
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className="custom_container">
        <div className="d-flex user_profile_dashboard">
          <nav className={`user-profile-sidebar ${toggleNav ? "close" : ""}`}>
            <header className="my-5">
              <Link to="/" target="_blank" className="text-decoration-none">
                <div className="mx-4 d-flex justify-content-center align-items-center">
                  <div className="text logo_img_wrapper">
                    {localStorage.getItem("LOGGED_IN_USER_PROFILE_PIC") !=
                    null ? (
                      <img
                        src={`${BACKEND_BASE_URL}${localStorage.getItem(
                          "LOGGED_IN_USER_PROFILE_PIC"
                        )}`}
                        alt="Profile"
                        className="w-100 h-100"
                      />
                    ) : (
                      <img
                        src={require("../Assets/avatar.png")}
                        alt="avatar"
                        className="w-100 h-100"
                      />
                    )}
                  </div>
                </div>
                <div className="flex_center mb-4"></div>
              </Link>

              <AiOutlineMenu
                className="bx bx-chevron-right toggle d-lg-none"
                onClick={() => setToggleNav(!toggleNav)}
              />
            </header>

            <div className="menu-bar">
              <div className="menu">
                <ul className="menu-links ps-0">
                  <NavLink to="/profile/">
                    <li className={`nav-link`} title="Account Overview">
                      <AiFillHome className="icon" />
                      <span className={`text nav-text `}>Account Overview</span>
                    </li>
                  </NavLink>

                  <NavLink to="edit-profile">
                    <li className={`nav-link`} title="Edit Profile">
                      <BsPencilFill className="icon" />
                      <span className={`text nav-text `}>Edit Profile</span>
                    </li>
                  </NavLink>
                  <NavLink to="change-password" title="Change Password">
                    <li className={`nav-link`}>
                      <HiLockClosed className="icon" />
                      <span className="text  nav-text me-3">
                        Change Password
                      </span>
                    </li>
                  </NavLink>
                  <NavLink
                    to="notification-settings"
                    title="Notification Settings"
                  >
                    <li className={`nav-link`}>
                      <MdNotifications className="icon" />
                      <span className="text  nav-text me-3">
                        Notification Settings
                      </span>
                    </li>
                  </NavLink>

                  <NavLink to="receipts">
                    <li className={`nav-link`}>
                      <BiTimeFive className="icon" />
                      <div className="d-none d-sm-block">
                        <span className="text nav-text me-3">Receipts</span>
                      </div>
                    </li>
                  </NavLink>

                  {/* <Link to="" onClick={LogoutUser} className="user_logout">
                <li className="nav-link">
                  <BiLogOut className="icon" />
                  <span className="text nav-text">Logout</span>
                </li>
              </Link> */}
                </ul>
              </div>
            </div>
          </nav>
          <section className="user-profile-rightbar">
            <Outlet />
          </section>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
