import axios from "axios";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { Form } from "react-bootstrap";
import { BACKEND_BASE_URL } from "../Component/GlobalVariables";
import { PassInputFieldFormControl } from "../Component/PassInputField";
import { ToastSuccess } from "../Component/ToastAlert";

const UserPassChange = () => {
  const userCurrentPassword = useRef();
  const userNewPassword = useRef();
  const userConfirmPassword = useRef();

  const [error, setError] = useState([]);

  const storeData = (e) => {
    const formdata = new FormData();

    if (userCurrentPassword.current.value) {
      formdata.append("current_password", userCurrentPassword.current.value);
      formdata.append("new_password", userNewPassword.current.value);
      formdata.append("confirm_password", userConfirmPassword.current.value);
    }

    axios
      .post(`${BACKEND_BASE_URL}/api/v2/user/profile/update`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("USER_ACCESS_TOKEN")}`,
        },
      })

      .then((response) => {
        if (response.data?.status === 200) {
          localStorage.setItem(
            "LOGGED_IN_USER_PROFILE_PIC",
            response.data?.user_profile?.profile_photo
          );
          ToastSuccess.fire({
            icon: "success",
            title: response.data?.message,
          });

          e.target.reset();
        } else {
          setError(response.data?.errors);
        }
      });
    e.preventDefault();
  };

  return (
    <div className="user_dashboard_content p-4 p-lg-5">
      <h1 className="mb-5 ms-3 ms-lg-0">Change password</h1>

      <div className="edit_password">
        <Form onSubmit={storeData} autoComplete="off">
          <Form.Group className="user_dashboard_content mb-4">
            <Form.Label className="mb-2">
              Current Password: <span className="text-danger">*</span>
            </Form.Label>
            <PassInputFieldFormControl
              inputRef={userCurrentPassword}
              placeholderValue="Current Password"
              eyeIconStyle="mb-1"
            />
            <div className="mb-2">
              <small className="text-danger">{error?.current_password}</small>
            </div>
          </Form.Group>

          <Form.Group className="user_dashboard_content mb-4">
            <Form.Label className="mb-2">
              New Password: <span className="text-danger">*</span>
            </Form.Label>
            <PassInputFieldFormControl
              inputRef={userNewPassword}
              placeholderValue="New Password"
              className=""
              eyeIconStyle="mb-1"
            />
            <div className="mb-2">
              <small className="text-danger">{error?.new_password}</small>
            </div>
          </Form.Group>

          <Form.Group className="user_dashboard_content mb-4">
            <Form.Label className="mb-2">
              Confirm Password: <span className="text-danger">*</span>
            </Form.Label>

            <PassInputFieldFormControl
              inputRef={userConfirmPassword}
              placeholderValue="Confirm Password"
              eyeIconStyle="mb-1"
            />

            <div className="mb-2">
              <small className="text-danger">{error?.confirm_password}</small>
            </div>
          </Form.Group>

          <div className="save_profile_btn mt-4 mb-5 d-flex justify-content-center justify-content-lg-end">
            <button className="me-3">Cancel</button>
            <button onClick={() => {}}>Set new Password</button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UserPassChange;
