import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import NotificationSettings from "./User Profile Dashboard/NotificationSettings";
import ReceiptDetails from "./User Profile Dashboard/ReceiptDetails";
import UserPassChange from "./User Profile Dashboard/UserPassChange";
import UserProfile from "./User Profile Dashboard/UserProfile";
import UserProfileDashboard from "./User Profile Dashboard/UserProfileDashboard";
import UserProfileEdit from "./User Profile Dashboard/UserProfileEdit";
import UserReceipts from "./User Profile Dashboard/UserReceipts";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="profile" element={<UserProfile />}>
            <Route index element={<UserProfileDashboard />} />
            <Route path="edit-profile" element={<UserProfileEdit />} />
            <Route path="change-password" element={<UserPassChange />} />
            <Route
              path="notification-settings"
              element={<NotificationSettings />}
            />
            <Route path="receipts" element={<UserReceipts />} />
            <Route path="receipts/:receiptId" element={<ReceiptDetails />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
